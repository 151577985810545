const LANDING_NAVIGATION_OPTIONS = [
  { name: "About", href: "/about" },
  {
    name: "News (See Starlo in action ➝)",
    href: "/jarrenrocks?collection=5a5f2155-6149-4df2-b4f8-3877144ba5ec",
  },
];

const LANDING_BOTTOM_NAVIGATION_OPTIONS = [
  { name: "About", href: "/about" },
  { name: "Terms", href: "/terms-and-conditions" },
  { name: "Privacy", href: "/privacy" },
  { name: "Pricing", href: "/pricing" },
  {
    name: "News",
    href: "/jarrenrocks?collection=5a5f2155-6149-4df2-b4f8-3877144ba5ec",
  },
  { name: "Contact", href: "/contact" },
];

const LANDING_USER_NAVIGATION_OPTIONS = [
  { name: "Home", href: "/home" },
  { name: "Profile", href: "/profile" },
  { name: "Settings", href: "/settings" },
];

const TEAM = [
  {
    name: "Jarren Rocks",
    role: "CEO",
    imageUrl: "/landing/jarren_rocks.jpeg",
    portfolioUrl: "https://jarren.rocks",
    starloUrl: "https://www.starlo.me/jarrenrocks",
    twitterUrl: "https://twitter.com/jarrenrocks_",
    linkedinUrl: "https://www.linkedin.com/in/jarrenrocks/",
  },
  {
    name: "Ray Chooi",
    role: "CTO",
    imageUrl: "/landing/ray_chooi.png",
    portfolioUrl: "https://raychooi.com/",
    starloUrl: "https://www.starlo.me/ray",
    twitterUrl: "https://twitter.com/Raymando282",
    linkedinUrl: "https://www.linkedin.com/in/raymond-chooi/",
  },
];

const CREATOR_PRICE = 23;
const REFERRAL_RATE = 0.5;

export {
  LANDING_NAVIGATION_OPTIONS,
  LANDING_BOTTOM_NAVIGATION_OPTIONS,
  LANDING_USER_NAVIGATION_OPTIONS,
  TEAM,
  CREATOR_PRICE,
  REFERRAL_RATE,
};
