import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";

import { db } from "utils/firebase";
import { useAuth } from "context/AuthContext";
import { Avatar } from "components/common";
import { getInitials } from "utils/textUtils";
import {
  LANDING_NAVIGATION_OPTIONS,
  LANDING_USER_NAVIGATION_OPTIONS,
} from "./constants";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NavBar() {
  const { user, logout } = useAuth();
  const { asPath } = useRouter();

  const [userData, userDataLoading] = useDocument(
    doc(db, `users`, user?.uid || "0")
  );

  const isCurrentNavItem = (href) => {
    if (href === "/contact" && asPath === "/contact") return true;
    return (
      asPath.split("#")[1]?.length &&
      asPath.split("#")[1] === href.split("#")[1]
    );
  };

  return (
    <Disclosure as="nav" className="absolute left-0 top-0 w-full">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 sm:py-2 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex">
                <div className=" flex flex-shrink-0 items-center">
                  <Link href="/" tabIndex={0}>
                    <Image
                      className="h-6 w-auto transition focus:outline-none focus:ring-2 focus:ring-orange-500 sm:mb-2 sm:h-8"
                      src="/images/starlo_logo.svg"
                      alt="Starlo Logo"
                      width={180}
                      height={37}
                      priority
                    />
                  </Link>
                </div>
              </div>

              <div className="flex items-center">
                <div className="-mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-orange-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-600">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

                <div className="hidden space-x-1 md:flex md:items-center md:space-x-4">
                  {LANDING_NAVIGATION_OPTIONS.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        isCurrentNavItem(item.href)
                          ? " text-orange-600"
                          : "text-gray-700 hover:bg-gray-100",
                        "rounded-md px-3 py-2 text-sm font-medium"
                      )}
                      aria-current={
                        isCurrentNavItem(item.href) ? "page" : undefined
                      }
                    >
                      {item.name}
                    </a>
                  ))}
                </div>

                {!user && !userDataLoading ? (
                  <div className="ml-4 hidden flex-shrink-0 md:flex">
                    <Link
                      href="/login"
                      className="relative inline-flex items-center gap-x-1.5 rounded-full bg-orange-600 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-700"
                    >
                      Log In
                    </Link>
                  </div>
                ) : (
                  <div className=" hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <Avatar
                            size="m"
                            initials={getInitials(userData?.data()?.name)}
                            imageUrl={userData?.data()?.profileImageUrl}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48">
                          <div className="origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5  focus:shadow-lg focus:outline-1 focus:ring-1 focus:ring-black focus:ring-opacity-5">
                            {LANDING_USER_NAVIGATION_OPTIONS.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    href={
                                      item.name === "Profile" &&
                                      userData?.data()?.username
                                        ? `${userData?.data()?.username}` ||
                                          item.href
                                        : item.href
                                    }
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => logout()}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block w-full px-4 py-2 text-left text-sm text-gray-700"
                                  )}
                                >
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className=" bg-white md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {LANDING_NAVIGATION_OPTIONS.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    isCurrentNavItem(item.href)
                      ? "bg-orange-100"
                      : "text-gray-700 hover:bg-orange-600 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={
                    isCurrentNavItem(item.href) ? "page" : undefined
                  }
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
            {user ? (
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5 sm:px-6">
                  <div className="flex-shrink-0">
                    <Avatar
                      size="m"
                      initials={getInitials(userData?.data()?.name)}
                      imageUrl={userData?.data()?.profileImageUrl}
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-400">
                      {userData?.data()?.username || ""}
                    </div>
                    <div className="text-sm font-medium text-gray-400">
                      {userData?.data()?.email || ""}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2 sm:px-3">
                  {LANDING_USER_NAVIGATION_OPTIONS.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={
                        item.name === "Profile" && userData?.data()?.username
                          ? `${userData?.data()?.username}` || item.href
                          : item.href
                      }
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-orange-600 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                  <Disclosure.Button
                    as="button"
                    onClick={() => logout()}
                    className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-orange-600 hover:text-white"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            ) : (
              <div className="mt-4 flex w-full justify-center">
                <Link
                  href="/login"
                  className="relative inline-flex items-center gap-x-1.5 rounded-full bg-orange-600 px-8 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-700"
                >
                  Log In
                </Link>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default NavBar;
